@font-face{font-family:"AlphaLyrae";src:url(../fonts/AlphaLyrae-Medium.woff) format("woff");font-display:swap}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  color: #FFF;
  font-size: 1em;
  line-height: 1.4;
}

body {
  position: relative;
  line-height: 1.2;
  min-height: 100vh;
  background-image: url(../assets/ScavBG.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.hidden,
[hidden] {
  display: none !important;
}

img {
  display: flex;
  max-width: 150px;
  height: auto;
  margin: 0 auto;
}
img.logo {
  max-width: 221px;
  height: 70px;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.sr-only.focusable:active,
.sr-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  white-space: inherit;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}

.clearfix::after {
  clear: both;
}

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print,
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 1.25dppx),
  (min-resolution: 120dpi) {
}

@media print {
  *,
  *::before,
  *::after {
    background: #fff !important;
    color: #000 !important;
    /* Black prints faster */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]::after {
    content: " (" attr(href) ")";
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  a[href^="#"]::after,
  a[href^="javascript:"]::after {
    content: "";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }
}

.container {
  padding: 0 15px;
  width: 100vw;
  /*max-width: calc(545px + 30px);*/
  margin: 0 auto;
  text-align: center;
}

.wrapper {
  max-width: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-areas:
    "header"
    "content";
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  min-height: 100vh;
}

.main-area {
  min-width: 100%;
  max-width: 100vw;
  grid-area: content;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.site-header {
  grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
  /*padding-top: 24px;*/
  padding-top: 85px;
  /*padding-bottom: 24px;*/
}

.field-group {
  max-width: 423px;
  margin: 0 auto 25px;
}
.content {
  margin-top: 25px;
}
.field-group
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="reset"]) {
  background: #f9fafa;
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #5f8afa;
  border: 0;
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 12px 8px;
  text-align: left;
}

.field-group
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="reset"]):focus {
  outline-color: #a1bcff;
}
.field-group input:not([type="radio"]):not([type="checkbox"])::placeholder {
  color: #a7a7a7;
}

input[type="submit"],
.btn {
  font-weight: 500;
  color: #000;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 22px;
  min-width: 210px;
  height: 44px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
input[type="submit"]:hover,
.btn:hover {
  background: #000;
  color: #fff;
}

.radio-field {
  text-align: left;
  line-height: 2;
}
.radio-field label {
  position: relative;
  padding-left: 23px;
}
.radio-field label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 1px;
  background: rgba(196, 196, 196, 0.1);
  border: 1.5px solid #d4d4d4;
  width: 17px;
  height: 17px;
  border-radius: 50%;
}
.radio-field input {
  position: absolute;
  opacity: 0;
}
.radio-field input:checked + label:before {
  background: #aad055;
  border-color: #aad055;
}

.title {
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  color: #fff;
}

.subtitle {
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  color: #262626;
}

.error-msg {
  font-weight: 600;
  line-height: 2.5;
  color: #db5555;
}

.error-icon {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  background-image: url("../assets/error-icon.svg");
  background-size: contain;
}

.win-page {
  padding: 0 20px;
}

.error-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.win-page-error {
  text-align: center;
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #db5555;
}

.radio-field {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radio-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  border: 1px solid #a7a7a7;
  border-radius: 50%;
}

.radio-button.active {
  border: 1px solid #5f8afa;
}

.radio-button-content {
  width: 10px;
  height: 10px;
  background: #5f8afa;
  border-radius: 50%;
}

.win-button {
  display: block;
  height: 56px;
  width: 100%;
  max-width: 423px;
  margin: 0 auto 40px;
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  background: #5f8afa;
  border: 0;
  border-radius: 80px;
  cursor: pointer;
}

/*
.win-button.disabled {
  background: #f9fafa;
  color: #a7a7a7;
}
*/

.button-wrap {
  display: flex;
  justify-content: center;
}

/*.site-logo { padding-top: 100px; }*/

.main-area .grid {
  /*max-width: 567px;*/
  /*max-width: 45vh;*/
  min-width: 290px;
  width: calc(100% - 30px);
  margin: 0 auto;
  max-width: 95vh;
  max-height: 50vh;
}
.main-area .clues {
  display: flex;
  justify-content: center;
  padding: 60px 0;
  margin: 0 auto;
  max-width: 60%;
  border-radius: 19px;
  box-shadow: 6px 6px lightslategrey;
  border: 1px solid lightslategray;
}
.main-area .clues .direction {
  margin: 0 6px;
  font-size: 16px;
  width: 50%;
}
.main-area .clues .direction h3 {
  font-size: 18px;
  color: #fff;
}

.main-area .grid [text-anchor="start"] {
  font-size: 41% !important;
}

.main-area .styles_WrapperUi__2TxXl {
  position: fixed;
}

.successful-page {
  height: 100%;
}

.successful-page-title {
  margin: 160px 0 20px;
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 42px;
  text-align: center;
  color: #262626;
}

.successful-text {
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  color: #262626;
}

.success-links {
  display: flex;
}

.second-text {
  margin: 15px 0 20px;
  width: 200px;
}

.near-link {
  display: block;
  border: 0.5px solid #262626;
  padding: 15px 38px;
  border-radius: 80px;
  text-decoration: none;
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #262626;
}

.near-link:hover {
  opacity: 0.7;
}
.bridge-text {
  margin: 0 auto 10px;
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #262626;
  width: 160px;
}

.defi-text {
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #262626;
}

.arrows {
  width: 15px;
  height: 19px;
  margin: 32px auto 40px;
  background: url("../assets/arrows.svg") center;
}

.clue-answer-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 16px;
}

.nav {
  position: absolute;
  right: 25px;
  top: 25px;
}

.add-word-container {
  display: flex;
  justify-content: center;
}

.field-group-border-top {
  border-top: 1px solid #ccc;
  margin-top: 25px;
  padding-top: 25px;
}

.field-group.clue-answer-item {
  margin-bottom: 8px;
}

.crossword-form{
  padding-top: 25px;
}

.field-group
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="reset"]).field-with-errors {
  border: 1px solid #db5555;
}

.field-group
  input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="reset"]).field-with-errors:focus {
  border: 1px solid #db5555;
  outline-color: #db5555;
}

.form-text {
  font-family: "AlphaLyrae",Helvetica,Arial,sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #262626;
}

div.bottom {
  min-height: 19px;
  margin-top: 50px
}

img.sponsor{
  border-radius: 50%;
  box-shadow: 0 .2rem .6remrgba(34,34,34,.05);
  height: 3.6rem;
  width: 3.6rem;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.7;
}

div.sponsor-container{
  display: flex;
}






section {
  display: flex;
  flex-flow: column;
  align-items: center;
}
section div.container {
  transition: all .3s ease;
}
section div.container h1 {
  margin: 15px 0 0 0;
}
section div.container h3 {
  margin: 0 0 25px 0;
}
@media (max-width: 992px) {
  section {
    padding: 0 20px 0 20px;
 }
}
section.slide-option {
  margin: 0 0 50px 0;
}
section.slide-option .no-marg {
  margin: 0 0 0 0;
}
div.highway-slider {
  display: flex;
  justify-content: center;
  width: 100%;
}
div.highway-slider div.highway-barrier {
  overflow: hidden;
  position: relative;
}
div.highway-slider ul.highway-lane {
  display: flex;
  height: 100%;
}
div.highway-slider ul.highway-lane li.highway-car {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #343434;
}
@keyframes translatestf {
  0% {
    transform: translateX(100%);
 }
  100% {
    transform: translateX(-500%);
 }
}
#stffull div.highway-barrier ul.highway-lane {
  width: 500%;
}
#stffull div.highway-barrier ul.highway-lane li.highway-car {
  animation: translatestf 30s linear infinite;
}
#stffull div.highway-barrier ul.highway-lane li.highway-car h4 {
  font-size: 28px;
}
@keyframes translateinfinite {
  100% {
    transform: translateX(calc(-2160px));
 }
}
#infinite div.highway-barrier::before, #infinite div.highway-barrier::after {
  content: " ";
  position: absolute;
  z-index: 9;
  width: 180px;
  height: 100%;
}
#infinite div.highway-barrier::before {
  top: 0;
  left: 0;
  background: linear-gradient(to right,#ffffff 0%,rgba(255,255,255,0) 100%);
}
#infinite div.highway-barrier::after {
  top: 0;
  right: 0;
  background: linear-gradient(to left,#ffffff 0%,rgba(255,255,255,0) 100%);
}
#infinite div.highway-barrier ul.highway-lane {
  width: 3420px;
}
#infinite div.highway-barrier ul.highway-lane li.highway-car {
  width: 65px; 
  animation: translateinfinite 30s linear infinite;
}
#infinite div.highway-barrier ul.highway-lane li.highway-car span.fab {
  font-size: 65px;
}

.text-center{
  text-align: center;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
